import { User } from './types';

const USER_INFO_KEY = 'userInfo';

const saveUser = (user: User | null): void => {
  if (user === null) {
    return sessionStorage.removeItem(USER_INFO_KEY);
  } else {
    sessionStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
  }
};

const tryRestoreUser = (): User | null => {
  let user: User | null;

  try {
    const stringData = sessionStorage.getItem(USER_INFO_KEY);
    user = stringData ? JSON.parse(stringData) as User : null;
  } catch (e) {
    console.warn('Bad locale storage data.');
    user = null;
  }

  return user;
};

export { saveUser, tryRestoreUser };
