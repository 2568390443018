//
// Created by kip on 5.05.21
//

import React, { ReactElement } from 'react';
import styles from './Loading.module.scss';
import { LoadingProps } from './types';

export default function Loading(props: LoadingProps): ReactElement {
  return (
    <div
      className={styles.container}
      {...props}
    >
      <div className={styles.spinner}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
