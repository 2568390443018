import { createContext } from 'react';
import { UserState } from './types';
import { saveUser, tryRestoreUser } from './sessionStorageAPI';

const initialUserState: UserState = {
  user: tryRestoreUser(),
  setUser: saveUser
};

const UserContext = createContext(initialUserState);

if (initialUserState.user === null) {
  // call API for restore cookie token
}

export default UserContext;
export { saveUser };
export { initialUserState };
