import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // for all options: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.REACT_APP_I18N_DEBUG === 'true',
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
      useSuspense: true
    }
  });

export default i18n;
